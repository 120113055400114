@import "site/sass-mixins.scss";

#page-about.page-standard {
  #timelines {
    >h2 {
      @include page-section;
    }
  }
  .timeline-block {
    max-width: 800px;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (min-width: 600px) {
      flex-wrap: nowrap;
    }

    justify-content: center;
    align-items: center;

    .sitepix-wrapper {
      width: 100%;
      // opacity: 0.7;
      picture {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $site-blue;
      opacity: 0.6;
    }

    &__panel {
      height: 45px;
      padding-bottom: 15%;
      flex: 0 0 80%;
      margin-bottom: 1em;
      @media screen and (min-width: 600px) {
        margin-bottom: 0;
        flex: 0 0 50%;
      }
      border-left: 12px solid $site-pink;
      margin-right: 20px;
      margin-left: 5%;
      position: relative;
      overflow: hidden;
      clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
      h2 {
        margin: 0;
        position: absolute;
        top: 8px;
        left: 15px;
        color: white;
        font-family: $font-heading;
        font-weight: 500;
        font-size: 7vw;
        line-height: 1.1em;
        @media screen and (min-width: 850px) {
          font-size: 50px;
        }
        max-width: 85%;
      }
    }
    &__content-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: 0 0 80%;
      @media screen and (min-width: 600px) {
        flex: 0 0 50%;
      }
    }
    &__content-spacer {
      flex: 0 1 0;
    }
    &__content {
      flex-grow: 0;

      box-sizing: border-box;
      padding-right: 40px;
      padding-left: 0;
      p {
        margin: 0;
        font-family: $font-copy;
        font-weight: 500;
        line-height: 1.1em;
        padding-bottom: 0.5em;
        font-size: 1em;
        @media screen and (min-width: 600px) {
          font-size: 1.3em;
        }
      }
    }
  }
  .odd .timeline-block {
    &__panel {
      border-left: none;
      border-right: 12px solid $site-blue;
      order: initial;
      @media screen and (min-width: 600px) {
        order: 3;
        margin-left: 20px;
      }

      margin-right: 5%;
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%);
      h2 {
        left: initial;
        right: 15px;
        text-align: right;
      }

    }
    &__mask {
      background-color: $site-pink;
    }
    &__content-spacer {
      flex: 1 1 2px;
    }
    &__content {
      @media screen and (min-width: 600px) {
        padding-left: 40px;
        padding-right: 0;
      }

      // text-align: right;
    }
  }
}