@import "site/sass-mixins.scss";

#page-system .diff-banner {
  // height: 500px;
  width: 100%;
  background-size: contain;
  // background-color: beige;
  // background-image: url(https://appzuka-web.s3.eu-west-2.amazonaws.com/buildeck/imagestream/n/background/hex1-4-1024.jpg);
  // background-color: blueviolet;
  // background: rgb(34,58,137);

  background: rgb(34,58,137);
  // background: linear-gradient(20deg, rgba(34,58,137,1) 3%, rgba(20,37,91,1) 29%, rgba(30,8,32,1) 52%, rgba(121,4,71,1) 77%, rgba(187,8,110,1) 98%);
  background: linear-gradient(20deg, rgba(187,8,110,1) 3%, rgba(121,4,71,1) 29%, rgba(30,8,32,1) 52%, rgba(20,37,91,1) 77%, rgba(34,58,137,1) 98%);
  


  // background: linear-gradient(29deg, rgba(34,58,137,1) 3%, rgba(20,37,91,1) 45%, rgba(121,4,71,1) 56%, rgba(221,8,129,1) 98%);
  // background-image: url('./whitehex.svg'), url('./bl.svg');
  // background-position: right top, left bottom;
  // background-repeat: no-repeat, no-repeat;
  text-align: center;
  font-family: $font-heading;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: white;
  &__pattern {
    // z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img.tophex {
     position: absolute;
     top: 0;
     left: 0;
    }
    img.bottomhex {
      position: absolute;
      transform: rotate(180deg);
      bottom: 0;
      left: 0;
     }
  }
  &__headline {
    z-index: 10;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 2rem;
    font-weight: 500;
    @media screen and (min-width: 700px) {
      font-size: 4vw;
    }
    @media screen and (min-width: 1200px) {
      font-size: 3rem;
    }
  }
  &__mission {
    z-index: 10;
    margin: 0;
    padding-bottom: 20px;
    font-size: 2.5vw;
    font-weight: 300;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.3rem;
    font-weight: 300;
    @media screen and (min-width: 700px) {
      font-size: 2.5vw;
    }
    @media screen and (min-width: 1200px) {
      font-size: 2rem;
    }
  }
  &__box-wrapper {
    z-index: 10;
    display: flex;

    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    justify-content: space-around;
    padding-bottom: 20px;
  }
  &__box {
    flex: 0 1 80%;
    margin-bottom: 1em;
    @media screen and (min-width: 768px) {
      flex: 0 1 45%;
    }
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media screen and (min-width: 768px) {
      flex-direction: column;
    }
  }
  &__box-heading {
    flex: 0 0 33%;
    @media screen and (min-width: 768px) {
      flex: 0 0 auto;
    }
    svg {
      width: 80px;
      height: 80px;
    }
    h2 {
      color: $site-blue;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__box-list {
    color: #333;
    padding-left: 20px;
    margin: 0;
    li {
      font-family: $font-copy;
      list-style-type: disc;
      text-align: left;
      font-size: 14px;
      line-height: 1.1em;
      padding-bottom: 10px;
    }
  }
}
