@import "site/sass-mixins.scss";

#page-system.page-standard {
  padding-left: 0;
  padding-right: 0;
  max-width: initial;
  .margin-container {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin: auto;
  }

//  .system-overview {
//    padding-top: 20px;
//    padding-bottom: 20px;
//     background-color: $site-pink;
//     color: white;
//     h2 {
//       font-family: $font-heading;
//       font-weight: 500;
//       font-size: 2.2vw;
//       text-align: center;
//     }
//   }


  .system-key-points {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $site-blue;
    color: white;
    h2 {
      text-align: center;
      font-family: $font-heading;
      font-weight: 500;
      font-size: 2.2vw;
    }
    ul {
      li {
        font-family: $font-heading;
        font-weight: 300;
        font-size: 2vw;
      }
    }
  }

  .system-techspecs {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $site-blue;
    color: white;
    h2 {
      text-align: center;
      font-family: $font-heading;
      font-weight: 500;
      font-size: 2.2vw;
    }
    ul {
      li {
        font-family: $font-copy;
        font-weight: 300;
        font-size: 1.5vw;
      }
    }
  }

  .system-order {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $site-pink;
    color: white;
    h2 {
      text-align: center;
      font-family: $font-heading;
      font-weight: 500;
      font-size: 2.2vw;
    }
    ul {
      li {
        font-family: $font-copy;
        font-weight: 300;
        font-size: 1.5vw;
      }
    }
  }
}
