// Logo
.buildeck-logo {

  .logo-buil-rect {
    fill:#223a89;
  }
  .logo-deck-rect {
    fill:#dd0881;
  }
  .logo-letter {
    fill:#1d1d1b;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:1px;
  }
}

.logo-large {
  .logo-letter {stroke-width:1.5px;}
}

.logo-small {
  .logo-letter {stroke-width:3px;}
}

.icon-box {
  svg {
    width: 100px;
    height: 100px;
  }
}

svg.custom-icon .icon-line {
  fill: none;
  stroke: #333;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

svg.custom-icon .icon-path {
  fill: #333;
}

.salogo-dark {
  .salogo-1 {
    fill: white;
  }

  .salogo-2 {
    fill: #063242;
  }
}

.salogo-light {
  .salogo-1 {
    fill: #063242;
  }

  .salogo-2 {
    fill: white;
  }
}


