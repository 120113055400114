@import "site/sass-mixins.scss";

// Overrides


@mixin dark-header-colors() {
        // background-color: #3c789c; //68b0e0
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3c789c+0,68b0e0+100 */
    background: $site-blue; /* Old browsers */
    background: -moz-linear-gradient(left, $site-blue 0%, #68b0e0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $site-blue 0%,#68b0e0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, #2945a3 0%, $site-blue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$site-blue', endColorstr='#68b0e0',GradientType=1 ); /* IE6-9 */
}

@mixin light-header-colors() {
    background: white;
}

body {
  overflow-x: hidden;
}

.full-logo {
  padding-top: 5px;
  padding-bottom: 3px;
  svg {
    display: block;
  }
  p {
    display: none;
    color:black;
    margin: 0;
    line-height: 1.2em;
  }
}

#headmenuwrapper {
  .topmenuouter {
    @include light-header-colors();
    .topmenubar {
      max-width: 1200px;
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
      .menubarlogolink {
        margin-left: 0;
        flex-grow: 0;
        .full-logo {
          svg {
            height: 40px;
          }
          p {
            display: inline-block;
            text-align: right;
            width: 100%;
            font-family: $font-logo;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      .content { 
        flex-grow: 1;
      }
    }
  }
  .hamburger-icon {
    margin-right: 0;
  }
}

#footerwrapper {
    @include dark-header-colors();
    .footer-content .footer-logo-link {
      padding-right: 20px;
      max-width: 200px;
    }
}

#topmenuwrapper .topmenuouter {
  background-color:rgba(255,255,255,0.9);
  .topmenubar {
    max-width: 1200px;
    margin: auto;
    .menubarlogolink svg {
      height: 35px;
    }
    #menucontent ul li a:visited {
      color: black;
    }
    #menucontent ul li a:hover {
      color: $highlight;
    }
  }
}

#footerwrapper .footer-copyright-wrapper {
    background-color:$site-blue;
}



#sub-head-menu {
  padding-left: 0;
  padding-right: 0;
  max-width: initial;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  #sub-head-menu-ul {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    >li {
      padding-left: 0;
    }
    @media screen and (max-width: 599px) {
      a:nth-child(2) li {
        padding-left: 0;
      }
    }
    a:last-child li {
      padding-right: 0;
    }
    li {
      list-style-type: none;
    }
  }
}



// Submenu colour white if top menu background is dark
// #sub-head-menu {

//     ul {
//         color: white;
//         a {
//             &, &:visited {
//                 color: white;
//             }
//             &.active-link {
//                 color: $highlight
//               }
//             @media (pointer: fine) { // Only activate hover for desktop devices to avoid double tap on touch
//               &:hover {
//                 color: $hover
//               }
//             }
//         }
//     }
// }

// #bannerwrapper.photobanner {
//     position: relative;
//     min-height: 66.6vw;
//     @media screen and (min-width: 551px) {
//         min-height: 33.3vw;
//     }
//     @media screen and (min-width: 1025px) {
//         min-height: 25vw;
//     }
//     picture {
//         line-height: 0;
//         position: absolute;
//     }
//     picture img { // Needed if srcset fails and default image chosen
//         width: 100%;
//     }
// }