@import "site/sass-mixins.scss";


#components {

    --transition-duration: 900ms;
    --transition-delay: 300ms;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1rem;

    h2 {
        font-family: $font-heading;
        font-weight: 500;
        font-size: 2em;  
        margin: 0;
    }
    p {
        width: 100%;
        font-family: $font-copy;
        font-weight: 300;
        font-size: 1.2em;   
        margin: 0;
        &.no-mouse {
            @media(hover: hover) and (pointer: fine) {
                display: none;
            }
        }
    }

    .component-block {
        flex: 1 1 100%;
        padding-bottom: 98%;
        @media screen and (min-width: 550px) {
            flex: 0 0 calc(50% - 1rem);
            padding-bottom: 48%;
        }
        @media screen and (min-width: 768px) {
            flex: 0 0 calc(33.3% - 0.66rem);
            padding-bottom: 32%;
        }
        @media screen and (min-width: 1024px) {
            flex: 0 0 calc(25% - 0.75rem);
            padding-bottom: 24%;
        }
        height: 0;

        position: relative;
        overflow: hidden;

        &__content-wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: background-color var(--transition-duration) ease-out;
            &:hover {
                background-color: rgba(221,8,129,0.7);
                p {
                    opacity: 1;
                }
            }
            &.odd:hover {
                background-color: rgba(34,58,137, 0.7)
            }
            display: flex;
            flex-direction: column;
            h2 {
                flex: 0 0 auto;
                font-family: $font-heading;
                font-weight: 500;
                font-size: 6vw;
                box-sizing: border-box;
                @media screen and (min-width: 550px) {
                    font-size: 3.3vw;
                }                 
                @media screen and (min-width: 768px) {
                    font-size: 2.4vw;
                } 
                @media screen and (min-width: 1024px) {
                    font-size: 1.8vw;
                }
                @media screen and (min-width: 1200px) {
                    font-size: 22px;
                }
                width: 100%;
                background-color: rgba(221,8,129,0.7);
                color: white;
                margin: 0;
                padding: 0.2em 0.5em;

            }
            &.odd h2 {
                background-color: rgba(34,58,137, 0.7)
            }


        }
        &__desc-wrapper {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {

                padding: 1em;
                box-sizing: border-box;
                opacity: 0;
                top: 0;
                transition: opacity var(--transition-duration) ease-out;

                font-family: $font-copy;
                font-weight: 500;
                font-size: 1rem;
                width: 100%;
                color: white;
            }
        }

        .sitepix-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
    }
}