@import "site/sass-mixins.scss";


#page-accreditations {
  h2 {
    @include page-section;
  }
}


.about-buildeck {
  h2 {
    @include page-section;
  }
}


.about-development {
  h2 {
    @include page-section;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 550px) {
      flex-direction: row;
    }
    flex-wrap: nowrap;
    gap: 1em;



  }
  &__content {
    order: 3;
    @media screen and (min-width: 550px) {
      order: 1;
    }
    flex: 1 1 auto;
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &__image-wrapper {
    order: 2;
    flex: 0 0 40%;
    align-items: flex-start;
    @media screen and (min-width: 650px) {
      flex: 0 0 33%;
    }
    // max-width: 250px;
    @media screen and (min-width: 800px) {
      max-width: initial;
    }
    gap: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media screen and (min-width: 550px) {
      flex-direction: column;
      flex: 0 0 40%;
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex: 0 0 50%;
    }
    .single-image {
      position: relative;
      flex: 1 0 45%;
      @media screen and (min-width: 550px) {
        flex: 1 1 90%;
      }

      @media screen and (min-width: 1024px) {
        flex: 1 0 45%;
      }
      .sitepix-wrapper {
        img {
          display: block;
          width: 100%;
        }

      }
      .image-date {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        font-size: 0.8em;
        box-sizing: border-box;
        background-color: rgba(0,0,0,0.5);
        color: white;
        padding: 0.2em 0.5em;
        margin: 0;
      }
    }

  }
}



.staff {
  display: flex;
  flex-direction: column;
  >h2 {
    @include page-section;
  }

  &__employee-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
    justify-content: space-between;
    align-content: flex-start;
    padding-top: 1em;
  }
  &__imagebox {
    flex: 1 0 80%;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 1em;
    @media screen and (min-width: 768px) {
      margin: 0;
      margin-right: 2em;
      margin-bottom: 0;
      flex: 0 0 300px;
    }
    // @media screen and (min-width: 768px) {
    //   flex: 0 0 25%;
    // }
    // @media screen and (min-width: 1024px) {
    //   flex: 0 0 20%;
    // }
    height: fit-content;
    position: relative;

    .sitepix-wrapper {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  &__content-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.5em 1em;
    box-sizing: border-box;
    background-color: rgba(34,58,137, 0.7);
    &.odd {
      background-color: rgba(221,8,129,0.7);
    }
    color: white;
    h2, h4 {
      width: 100%;
      margin: 0;
      font-family: $font-heading;
    }
    h2 {
      font-weight: 500;
      font-size: 2rem;
      @media screen and (min-width: 550px) {
        font-size: 3.3vw;
      }                 
      @media screen and (min-width: 768px) {
          font-size: 2.4vw;
      } 
      @media screen and (min-width: 1024px) {
          font-size: 1.8vw;
      }
      @media screen and (min-width: 1200px) {
          font-size: 22px;
      }
    }
    h4 {
      font-weight: 300;
      font-size: 1.5rem;
    }
  }

  &__bio {
    min-width: 200px;
    width: 100%;
    flex-grow: 1;
    p {
      // max-width: 60ch; Removed when Fred pix removed
      margin: 1em auto;
      margin-bottom: 0;
    }
    p:first-child {
      margin-top: 0;
    }

  }
}

.about-as-wrapper {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 550px) {
    flex-direction: row;
  }
  justify-content: space-between;
  .about-as-logo {
    flex: 0 0 25%;

    @media screen and (max-width: 549px) {
      .svg-wrapper {
        max-width: 350px;
        margin: auto;
      }
    }

  }
  .about-as-content {
    flex: 1 1 auto;
    padding-left: 20px;
    p {
      margin-top: 0;
    }
  }
}

.about-as2-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .about-as2-logo {
    // flex: 0 0 25%;
    margin-top: 10px;
    &:first-child {
      // outline: 1px solid red;
      margin-top: 0;
    }
    width: 75%;
    max-width: 200px;
    // outline: 1px solid red;
    img {
      width: 100%;
    }
    @media screen and (max-width: 549px) {
      .svg-wrapper {
        max-width: 350px;
        margin: auto;
      }
    }

  }
  @media screen and (min-width: 550px) {
    flex-direction: row;
    .about-as2-logo {
      flex: 0 1 20%;
      max-width: 200px;
      img {
        width: 100%;
      }
  
  
      @media screen and (max-width: 549px) {
        .svg-wrapper {
          max-width: 350px;
          margin: auto;
        }
      }
  
    }
  }


  .about-as-content {
    flex: 1 1 auto;
    padding-left: 20px;
    p {
      margin-top: 0;
    }
  }
}