#bannerwrapper.photobanner {
    height: 0;
    padding-bottom: 66.6%;
    min-height: initial;
    @media screen and (min-width: 550px) {
        min-height: initial;
        padding-bottom: 33.3%;
    }
    @media screen and (min-width: 1200px) {
        min-height: initial;
        padding-bottom: 25%;
    }
}
.footer-content {
  .footer-logo-link {
    svg.buildeck-logo {
        border: 1px solid white;
        min-width: 200px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .salogo-light {
        display: none;
        @media screen and (min-width: 600px) {
            display: block;
        }
    }

  }
}