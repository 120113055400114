#accreditations-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;

    picture {
        flex: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 20px;
        // &:first-child {
        //     outline: 1px solid red;
        //     flex: 0.1 1 10%;
        // }
        padding-right: 10px;
        &:last-child {
            padding-right: 0;
        }
        img {
            height: 9vw;

            @media screen and (min-width: 768px) {
                height: 4vw;
            }
            width: auto;
            // max-height: 30px;
        }

    }

}